<template>
    <div class="batch_page">
        <div class="wrapper batch_page-wrapper">
            <div class="batch_page-security">
                <div
                    v-for="(item, index) in security"
                    :key="index"
                    class="batch_page-security__wrapper"
                >
                    <img
                        class="batch_page-security__img"
                        :src="item.icon"
                    >
                    <div class="batch_page-security__description-block">
                        <span
                            class="batch_page-security__title"
                        >{{ item.title }}</span>
                        <span
                            class="batch_page-security__description"
                        >
                            {{ item.description }}
                        </span>
                    </div>
                </div>
            </div>
            <p
                v-if="paymethods.length > 1"
                class="payment_label"
            >
                How would you like to pay?
            </p>
            <!-- paymethods -->
            <div
                v-if="paymethods.length > 1"
                class="paymethods"
            >
                <div
                    v-for="paymethod in paymethods"
                    :key="paymethod.type"
                    class="paymethods_item"
                    :class="{
                        'paymethods_item--active': activePaymethod === paymethod.type,
                        'paymethods_item--active-no-active': activePaymethod !== paymethod.type,
                        'paymethods_item--new': paymethod.type === 'bitfinex'
                    }"
                    @click="chooseActivePaymethod(paymethod.type)"
                >
                    <img
                        :src="paymethod.logo"
                        alt="worldpay"
                    >
                    <span>{{ paymethod.title }}</span>
                </div>
            </div>
            <!-- main -->
            <div style="display: flex; justify-content: space-between">
                <main class="payment_info">
                    <PaymentDetailsCreditCard
                        v-if="activePaymethod === 'worldpay'"
                        ref="paymentCard"
                        :block-card="blockCard"
                        @validated="onEventValidated"
                    />
                    <PaymentDetailsWorldPay350
                        v-if="activePaymethod === 'worldpay350'"
                        class="payment-worldpay350"
                    />
                    <PaymentDetailsBitfinex
                        v-if="activePaymethod === 'bitfinex'"
                        @bitfinexLoading="onBitfinexLoading"
                    />
                </main>
            </div>
            <footer class="payment_footer">
                <div class="payment_footer__wrapper">
                    <router-link
                        :to="{name: 'checkout'}"
                        class="payment_footer_btn payment_footer_btn--cart btn-border"
                    >
                        back to cart
                    </router-link>
                    <custom-button
                        :to="{name: 'account'}"
                        class="payment_footer_btn payment_footer_btn--proceed btn-main"
                        :loading="payButtonLoading"
                        @on-btn-click="proceed"
                    >
                        <span class="payment_footer_btn--proceed-title">Pay</span>
                        <span>{{ total | money }}</span>
                    </custom-button>
                </div>
            </footer>
        </div>
        <RadiusLoader v-if="isCallback" />
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'
import Api from '@/helpers/api/index.js'
import filtersMixin from '@/mixins/filtersMixin.js'

import { getAllowedPaymentSystem, security } from '@/services/settings'

// components
import PaymentDetailsCreditCard from '@/components/checkout/PaymentDetailsCreditCard.vue'
import PaymentDetailsWorldPay350 from '@/components/checkout/PaymentDetailsWorldPay350.vue'
import PaymentDetailsBitfinex from '@/components/checkout/PaymentDetailsBitfinex.vue'
import RadiusLoader from '@/components/common/RadiusLoader'

import {
    ACTION_WORLDPAY_GET_TOKEN,
    ACTION_PAY_WITH_CARD_FIRST,
    ACTION_PAY_WITH_CARD_SECOND,
    ACTION_PAY_WORLDPAY_350
} from '@/store/modules/checkout/action-types.js'

import {
    SET_PAYMENT_ERROR
} from '@/store/modules/checkout/mutation-types.js'

const {
    mapActions: mapCheckoutActions,
    mapMutations: mapCheckoutMutations
} = createNamespacedHelpers('checkout')
export default {
    name: 'BatchPage',
    components: {
        PaymentDetailsCreditCard,
        PaymentDetailsWorldPay350,
        RadiusLoader,
        PaymentDetailsBitfinex
    },
    mixins: [
        filtersMixin
    ],
    data() {
        return {
            blockCard: false,
            total: 0,
            activePaymethod: null,
            IsValidCreditCard: false,
            isCallback: false,
            cardinal: false,
            payButtonLoading: false,
            paymethods: null
        }
    },
    computed: {
        ...mapGetters('checkout', [
            'getterCardBin',
            'getterBitfinexId'
        ]),
        batchid() {
            return this.$route.params.batchid
        },
        security() {
            return security
        }
    },
    async created() {
        if (this.$route.name === 'checkout_success') return
        this.initPaymethods()
        if (this.$route.query.PaRes) {
            await this.worldpayListener()
        } else {
            await this.getBatchStatus()
        }
        // window.addEventListener('message', (event) => {
        //     if (event.origin === 'https://centinelapistag.cardinalcommerce.com') {
        //         const data = JSON.parse(event.data);
        //         if (data !== undefined && data.Status && !this.cardinal) {
        //             this.cardinal = true
        //             document.querySelector('#ddcIframe').remove()
        //             this[ACTION_PAY_WITH_CARD_FIRST]({ invoice_id: this.batchid, ddc: { ...data } })
        //                 .then((response) => {
        //                     if (response.token) {
        //                         this.challangeIframe(response.token)
        //                     } else if (response.action === 'success') {
        //                         this.$router.push({ name: 'checkout_success' })
        //                     }
        //                 })
        //                 .catch((error) => {
        //                     eventBus.$emit('showSnackBar', error, 'error')
        //                     this[SET_PAYMENT_ERROR]({ error, isExpiredBatch: false })
        //                     this.$router.push({ name: 'checkout_error' })
        //                 })
        //                 .finally(() => {
        //                     this.payButtonLoading = false
        //                 })
        //         }
        //     }
        // }, false);
    },
    methods: {
        ...mapCheckoutActions([
            ACTION_WORLDPAY_GET_TOKEN,
            ACTION_PAY_WITH_CARD_FIRST,
            ACTION_PAY_WITH_CARD_SECOND,
            ACTION_PAY_WORLDPAY_350
        ]),
        ...mapCheckoutMutations([
            SET_PAYMENT_ERROR
        ]),
        chooseActivePaymethod(type) {
            this.activePaymethod = type
        },
        initPaymethods() {
            this.paymethods = getAllowedPaymentSystem()
            this.activePaymethod = this.paymethods[0].type
        },
        onEventValidated(e) {
            this.IsValidCreditCard = e
        },
        async getBatchStatus() {
            try {
                const { data } = await Api.get('/api/order-batch/status', { invoice_id: this.batchid })
                this.total = data.total
            } catch (error) {
                this[SET_PAYMENT_ERROR]({ error, isExpiredBatch: true })
                this.$router.push({ name: 'checkout_error' })
            }
        },
        onBitfinexLoading(e) {
            this.payButtonLoading = e
        },
        async proceed() {
            if (this.activePaymethod === 'worldpay' && !this.IsValidCreditCard) {
                this.$refs.paymentCard.$v.$touch()
                eventBus.$emit('showSnackBar', 'The credit card is not valid', 'error')
                return
            }
            if (this.activePaymethod === 'bitfinex') {
                window.open(`https://pay.bitfinex.com/gateway/order/${this.getterBitfinexId}`, '_blank').focus();
                return
            }
            this.payButtonLoading = true
            if (this.activePaymethod === 'worldpay') {
                try {
                    this.blockCard = true
                    const response = await this[ACTION_PAY_WITH_CARD_FIRST]({ invoice_id: this.batchid })
                    if (response.action === 'success') {
                        this.$router.push({ name: 'checkout_success' })
                    }
                    // this.performDDC()
                } catch (error) {
                    this[SET_PAYMENT_ERROR]({ error, isExpiredBatch: false })
                    this.$router.push({ name: 'checkout_error' })
                    eventBus.$emit('showSnackBar', error, 'error')
                    this.payButtonLoading = false
                }
            }
            if (this.activePaymethod === 'worldpay350') {
                try {
                    await this[ACTION_PAY_WORLDPAY_350]({
                        batchid: this.batchid
                    })
                } catch (error) {
                    eventBus.$emit('showSnackBar', error, 'error')
                    this.payButtonLoading = false
                }
            }
        },
        // second requests
        async worldpayListener() {
            try {
                this.isCallback = true
                await this[ACTION_PAY_WITH_CARD_SECOND]({ query: this.$route.query, batchid: this.batchid })
                this.$router.push({ name: 'checkout_success' })
            } catch (error) {
                this[SET_PAYMENT_ERROR]({ error, isExpiredBatch: false })
                this.$router.push({ name: 'checkout_error' })
            } finally {
                this.isCallback = false
            }
        }
        // performDDC() {
        //     const innerHtml = `
        //     <body onload='document.collectionForm.submit();'>
        //         <form id="collectionForm" name="collectionForm" method="POST" action="https://centinelapistag.cardinalcommerce.com/V1/Cruise/Collect">
        //             <input type="hidden" name="Bin" value="${this.getterCardBin}" />
        //             <input type="hidden" name="JWT" value="${this.jwtToken}" />
        //         </form>
        //     </body>
        //     `

        //     // set iframe properties
        //     const iframe = document.createElement('iframe');
        //     iframe.id = 'ddcIframe';
        //     iframe.width = 0;
        //     iframe.height = 0;
        //     iframe.style.visibility = 'hidden';
        //     iframe.style.display = 'none';
        //     iframe.target = '_top';
        //     iframe.srcdoc = innerHtml;

        //     document.body.appendChild(iframe);
        // },
        // async challangeIframe(token) {

        //     const innerHtml = `
        //     <body onload='document.challengeForm.submit();'>
        //         <form id="challengeForm" name="challengeForm" method="POST" action="https://centinelapistag.cardinalcommerce.com/V2/Cruise/StepUp">
        //             <input type="hidden" name="JWT" value="${token}" />
        //         </form>
        //     </body>
        //     `

        //     // set iframe properties
        //     const iframe = document.createElement('iframe');
        //     iframe.width = 400;
        //     iframe.height = 250;
        //     iframe.srcdoc = innerHtml;

        //     document.body.appendChild(iframe);
        // }
    }
}
</script>

<style lang="scss">
    .payment_label{
        font-weight: 700;
        font-size: 22px;
        margin: 40px;

    }
    .paymethods{
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 40px;
        border-bottom: 1px solid #EAEAEA;
        @media all and (max-width: 768px) {
            flex-wrap: wrap;
            margin-bottom: 0;
        }
        &_item {
            border-radius: 4px;
            height: 130px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            text-transform: uppercase;
            border: 1px solid rgba($main-color, 0.4);
            margin-right: 40px;
            font-size: 14px;
            padding: 20px;
            width: 230px;
            cursor: pointer;
            &:last-child{
                margin-right: 0;
            }
            @media all and (max-width: 768px) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 20px;
            }
            img {
                max-width: 70%;
                margin-bottom: 5px;
            }
            span {
                white-space: nowrap;
            }
            &--active {
                background: rgba($main-color, 0.2);
                border-color: rgba($main-color, 1);
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    border: 1px solid rgba($main-color, 1);
                    background: url('~@/assets/img/svg/check-white.svg') no-repeat;
                    background-size: 10px 10px;
                    background-color:$main-color;
                    background-position: center center;
                }
            }
            &--active-no-active{
                border-color: rgba($main-color, 1);
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    border: 1px solid rgba($main-color, 1);
                }
            }
            &--new {
                position: relative;
                &:after {
                    content: "new";
                    position: absolute;
                    top: 5px;
                    right: -10px;
                    width: 30px;
                    height: 14px;
                    border-radius: 4px;
                    border: 1px solid #4a9ce1;
                    background-color: #128823;
                    color: white;
                    display: flex;
                    align-items: center;
                    padding: 3px;
                    justify-content: center;
                    font-size: 12px;
                }
            }
        }
    }
    .payment_footer{
        width: 100%;
        align-items: center;
        margin-top: 25px;
        padding: 25px 0;
        border-top: 1px solid #EAEAEA;
        background-color: #fff;
        &__wrapper{
            width: 90%;
            display: flex;
            align-items: center;
            margin: 0 auto;
            @media all and (max-width: 420px) {
                flex-direction: column;
            }
        }
        &_btn {
            border-radius: 4px;
            text-transform: uppercase;
            text-align: center;
            padding: 16px 25px;
            font-weight: 700;
            font-size: 18px;
            width: 200px;
            &--account{
                color: $light-grey;
                border: 1px solid $light-grey;
            }
            &--cart{
                color: $light-grey;
                //text-decoration: underline;
                margin-right: 30px;
                white-space: nowrap;
                font-size: 14px;
                border: 1px solid rgba(0, 0, 0, 0.2) !important;
                text-decoration: underline;
                @media all and (max-width: 420px) {
                    margin-right: 0;
                    margin-bottom: 15px;
                    width: 100%;
                }
            }
            &--proceed{
                margin-left: auto;
                border: none;
                width: 200px;
                & > span {
                    font-weight: 700;
                }
                @media all and (max-width: 420px) {
                    width: 100%;
                }
            }
            &--proceed-title{
                margin-right: 15px;
            }
        }
    }
    a {
        transition: none;
    }
    .payment_info{
        //width: 700px;
        .payment-details__cards{
            margin: 0;
            margin-top: 20px;
        }
    }
    .batch_page{
        padding: 5px;
        .overlay{
            position: fixed;
            z-index: 99;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(0,0,0,0.2);
        }
    }

    .batch_page-wrapper {
        border-radius: 4px;
        box-shadow: 0 0 10px 3px rgb(25 50 71 / 25%);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .payment-worldpay350{
        width: 700px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #787e86;
        font-size: 18px;
        text-align: center;
        &__btn{
            cursor: pointer;
            color: $main-color;
            &:hover {
                color: #787e86;
            }
        }
        @media (max-width: 800px) {
            width: 100%;
        }
    }

    .batch_page-security{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #F2F6FF;
        border-bottom: 1px solid #EAEAEA;
        width: 100%;
        @media (max-width: 768px) {
            flex-wrap: wrap;
        }
        &__wrapper{
            display: flex;
            align-items: center;
            padding: 40px;
            @media (max-width: 768px) {
                width: 100%;
                padding: 20px 40px;
            }
        }
        &__description-block{
            margin-left: 25px;
            display: flex;
            flex-direction: column;
        }
        &__img{
            width: 48px;
            height: 48px;
        }
        &__title{
            font-weight: 700;
            font-size: 18px;
            line-height: 26px;
        }
        &__description{
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }
    }
</style>
