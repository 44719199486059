<template>
    <div class="payment-details__info worldpay350">
        <span class="worldpay350__info">
            <hint-info
                :text="'Worldpay gateway accepts many credit / debit cards including but not limited to American Express, Diners Club International, JCB, Maestro, Mastercard, Visa.'"
            />
            <span class="pay-image worldpay350__info-cards">
                <img
                    class="worldpay350__cards-american-express"
                    src="../../assets/img/cards/american-express.png"
                    alt="American Express"
                >
                <img
                    class="worldpay350__cards-diner-club"
                    src="../../assets/img/cards/diner-club.png"
                    alt="Diner Club"
                >
                <img
                    class="worldpay350__cards-jcb"
                    src="../../assets/img/cards/jcb.png"
                    alt="JCB"
                >
                <img
                    class="worldpay350__cards-maestro"
                    src="../../assets/img/cards/maestro.png"
                    alt="Maestro"
                >
                <img
                    class="worldpay350__cards-master-card"
                    src="../../assets/img/cards/master-card.png"
                    alt="Master Card"
                >
                <img
                    class="worldpay350__cards-visa"
                    src="../../assets/img/cards/visa.png"
                    alt="Visa"
                >
            </span>
        </span>
    </div>
</template>
<script>
// components
import HintInfo from '@/components/common/Hint'

export default {
    name: 'PaymentDetailsWorldPay350',
    components: {
        HintInfo
    }
}
</script>

<style lang="scss" scoped>

.worldpay350 {
    &__info-cards {
        display: flex;
        justify-content: center;
        align-items: center !important;
    }
    &__cards-american-express{
        height: 70px !important;
        width: 70px;
    }
    &__cards-diner-club{
        height: 50px !important;
        width: 70px;
    }
    &__cards-jcb{
        height: 60px !important;
        width: 70px;
    }
    &__cards-maestro,
    &__cards-master-card,
    &__cards-visa{
        height: 70px !important;
        width: 70px;
        margin-top: 0 !important;
    }
}

</style>
